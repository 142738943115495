(function () {
  'use strict';

  angular
    .module('neo.public.classification')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('public.classification', {
        url: '/championships/{championshipId}/classification',
        templateUrl: 'public/classification/classification.tpl.html',
        controller: 'ClassificationCtrl',
        controllerAs: 'vm',
        resolve: {
          championship: function (ChampionshipPublic, $stateParams) {
            return ChampionshipPublic.get({id: $stateParams.championshipId}).$promise;
          },
          teams: function (ChampionshipTeamsPublic, $stateParams) {
            return ChampionshipTeamsPublic.query({id: $stateParams.championshipId}).$promise;
          },
          classification: function (ChampionshipClassification, $stateParams) {
            return ChampionshipClassification.query({id: $stateParams.championshipId}).$promise;
          },
          matchDay: function (ChampionshipMatchesPublic, $stateParams) {
            return ChampionshipMatchesPublic.get({id: $stateParams.championshipId}).$promise;
          },
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          }
        }
      });
  }
}());
